import Image from 'next/image'
import BrandPic from 'public/brand-content.png'
import SwapRightOutline from 'public/swap-right-outline.svg'
import React, { FC } from 'react'

interface IProps {
  title: string
  mainContent: string
  description: string
  targetLink: string
}

const BrandContent: FC<IProps> = ({ title, mainContent, description, targetLink }) => {
  return (
    <section className="relative -mx-4 lg:-mx-18 px-18 lg:px-30 text-white flex flex-col justify-center items-start h-[calc((100vw-2rem)/2.45)] lg:h-[calc((100vw-6rem)/3.392)] min-h-[18.75rem] lg:min-h-[22rem] min-w-[46rem]">
      <Image src={BrandPic} alt="Brand" placeholder="blur" fill className="object-cover" />
      <div className="z-10">
        <div className="text-sm font-light lg:font-medium lg:text-base">{title}</div>
        <div className="text-xl font-bold mt-1 lg:mt-3 lg:text-3xl">{mainContent}</div>
        <div className="text-xs mt-2 max-w-[16rem] lg:max-w-[22.5rem] lg:text-base lg:mt-5">{description}</div>
        <div
          onClick={() => {
            window.open(targetLink, '_blank')
          }}
          className="cursor-pointer lg:mt-10 mt-6 w-[6.875rem] lg:w-[9.875rem] lg:h-12 h-10 lg:pl-10 lg:pr-8 pl-6 pr-5 flex items-center bg-transparent hover:bg-opacity-10 hover:bg-white border border-white border-solid mb-[0.375rem] lg:mb-4 rounded-sm"
        >
          <span className="text-white lg:mr-3 mr-1 lg:text-sm text-xs">了解更多</span>
          <SwapRightOutline className="stroke-white lg:w-4 lg:h-4 w-3 h-3" />
          <div />
        </div>
      </div>
    </section>
  )
}

export default BrandContent
