import useBetterMediaQuery from '@/hooks/use-better-media-query'
import { SecondLevelCategory } from '@/models/products/first-level-category'
import { CategoryStyleEnum } from '@/models/products/product'
import Image from '@/ui/widgets/image'
import cls from 'classnames'
import Link from 'next/link'
import { useMemo, useState } from 'react'

enum ImageCount {
  Two = 2,
  Four = 4,
  Three = 3,
  Five = 5,
  Six = 6,
}
interface Config {
  containerClassName: string
  specialGridTextWidth?: string[]
  gridClassNameList: string[]
}

const GRID_CONFIG: Record<ImageCount, Config> = {
  [ImageCount.Six]: {
    containerClassName: 'grid-cols-2 lg:grid-cols-3 grid-rows-4 lg:grid-rows-3 h-[30.125rem] lg:h-[43.375rem]',
    specialGridTextWidth: ['lg:max-w-[16.5rem] max-w-[11.5rem]'],
    gridClassNameList: [
      'row-start-1 row-end-3 col-span-1 lg:row-start-1 lg:row-end-3 lg:col-start-1 lg:col-end-3',
      'row-start-1 row-end-3 col-span-1 lg:row-start-1 lg:row-end-2 lg:col-start-3 lg:col-end-4',
      'row-start-3 row-end-4 col-span-1 lg:row-start-2 lg:row-end-3 lg:col-start-3 lg:col-end-4',
      'row-start-3 row-end-4 col-span-1 lg:row-start-3 lg:row-end-4 lg:col-start-1 lg:col-end-2',
      'row-start-4 row-end-4 col-span-1 lg:row-start-3 lg:row-end-4 lg:col-start-2 lg:col-end-3',
      'row-start-4 row-end-4 col-span-1 lg:row-start-3 lg:row-end-4 lg:col-start-3',
    ],
  },
  [ImageCount.Five]: {
    containerClassName: 'grid-cols-2 lg:grid-cols-3 grid-rows-3 lg:grid-rows-5 h-[30.125rem] lg:h-[43.375rem]',
    specialGridTextWidth: ['lg:max-w-[16.5rem] max-w-[11.5rem]'],
    gridClassNameList: [
      'row-start-1 row-end-3 col-span-1 lg:row-start-1 lg:row-end-4 lg:col-start-1 lg:col-end-3',
      'row-start-1 row-end-2 col-span-2 lg:row-start-1 lg:row-end-3 lg:col-span-3',
      'row-start-2 row-end-3 col-span-2 lg:row-start-4 lg:row-end-6 lg:col-span-1',
      'row-start-3 row-end-4 col-span-1 lg:row-start-4 lg:row-end-6 lg:col-start-2 lg:col-end-3',
      'row-start-3 row-end-4 col-span-2 lg:row-start-3 lg:row-end-6 lg:col-span-3',
    ],
  },
  [ImageCount.Three]: {
    containerClassName: 'grid-cols-2 grid-rows-2 h-[19.75rem] lg:h-[33.25rem]',
    specialGridTextWidth: ['', 'lg:max-w-[16.25rem] max-w-[7rem]', 'lg:max-w-[16.25rem] max-w-[8.75rem]'],
    gridClassNameList: [
      'row-start-1 row-end-3 col-start-1 col-end-2',
      'row-start-1 row-end-2 col-start-2 col-end-3',
      'row-start-2 row-end-3 col-start-2 col-end-3',
    ],
  },
  [ImageCount.Four]: {
    containerClassName: 'grid-cols-2 lg:grid-cols-12 grid-rows-2 h-[20.25rem] lg:h-[33.25rem]',
    gridClassNameList: [
      'lg:row-start-1 lg:row-end-2 lg:col-start-1 lg:col-end-6',
      'lg:row-start-1 lg:row-end-2 lg:col-start-6 lg:col-end-13',
      'lg:row-start-2 lg:row-end-3 lg:col-start-1 lg:col-end-8',
      'lg:row-start-2 lg:row-end-3 lg:col-start-8 lg:col-end-13',
    ],
  },
  [ImageCount.Two]: {
    containerClassName: 'grid-cols-2 grid-rows-1 h-[17.5rem] lg:h-[26.625rem]',
    gridClassNameList: [],
  },
}
const DEFAULT_GRID_CONFIG = {
  containerClassName: 'grid-cols-2 auto-rows-[20rem]',
  specialGridTextWidth: [],
  gridClassNameList: [],
}

interface GridImageItem extends SecondLevelCategory {
  gridItemClassName: string
  gridTextMaxWidth?: string
}

const ImageItem = ({ product }: { product: GridImageItem }) => {
  const matches = useBetterMediaQuery()

  return (
    <Link key={product.id} className={`bg-gray-20 relative cursor-pointer ${product.gridItemClassName}`} href={product.href}>
      <div className={cls('absolute z-10 lg:top-10 top-6 xlg:left-12 lg:left-8 left-6 lg:right-4 xlg:right-0', product.gridTextMaxWidth ?? '')}>
        <p className="text-gray-600 font-bold lg:text-2xl text-lg space-x-2">
          <span>{product.name}</span>
          {product.style === CategoryStyleEnum.New && (
            <span style={{ borderRadius: '6px 6px 6px 0px' }} className="bg-[#ED4F4F] text-white lg:text-base h-10 p-1.5 lg:relative lg:bottom-1">
              NEW
            </span>
          )}
        </p>
        <p className="text-gray-500  font-[350] lg:text-lg text-sm lg:mt-2 mt-1">{product.recommendation}</p>
      </div>
      <Image
        src={matches ? product.desktopPictureUrl ?? '' : product.tabletPictureUrl ?? ''}
        alt="product"
        className={cls('absolute right-0 bottom-0 object-contain object-right-bottom xlg:h-full lg:h-4/5 h-full', product.imgClassName)}
        width={1200}
        height={1200}
      />
    </Link>
  )
}

const GridImages = ({ data }: { data: (SecondLevelCategory & { gridTextMaxWidth: string })[] }) => {
  const [config, setConfig] = useState<Config>({} as Config)
  const list = useMemo(() => {
    if (!data.length) return []

    const gridAmountOutOfScope = data.length > 6 || data.length < 2
    const gridConfig = gridAmountOutOfScope ? DEFAULT_GRID_CONFIG : GRID_CONFIG[data.length as ImageCount]
    setConfig(gridConfig)
    return data.map((item, index) => {
      const gridTextMaxWidth = gridConfig.specialGridTextWidth?.[index] ?? item.gridTextMaxWidth
      return { ...item, gridItemClassName: gridConfig.gridClassNameList[index] ?? '', gridTextMaxWidth }
    })
  }, [data])

  return (
    <>
      <div className={cls('w-full grid gap-4 lg:gap-6', config.containerClassName)}>
        {list.map((product: GridImageItem, index) => (
          <ImageItem key={`${product.id}${index}`} product={product} />
        ))}
      </div>
    </>
  )
}

export default GridImages
