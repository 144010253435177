import { RecommendCategory } from '@/models/products/product'
import GridImages from '@/ui/components/shared/grid-images'
import Image from '@/ui/widgets/image'
import { useRouter } from 'next/router'
import { FC, useMemo } from 'react'

const PopularProductsCategories: FC<{ productsCategories?: RecommendCategory[] }> = ({ productsCategories }) => {
  const router = useRouter()

  const list = useMemo(() => {
    if (!productsCategories?.length) return []
    return productsCategories.map((item) => {
      return {
        ...item,
        href: `/products?secondLevelCategoryId=${item.secondLevelCategoryId}`,
        gridTextMaxWidth: 'lg:max-w-[16.5rem] max-w-[11.375rem]',
      }
    })
  }, [productsCategories])

  const handeGoCmsContent = () => {
    void router.push(`/content-management`)
  }

  return (
    <>
      <div className="lg:mt-20 mt-10 mb-16 lg:mb-20">
        <h2 className="lg:text-2xl text-xl font-bold text-gray-600 mb-6 lg:mb-8">热门产品分类</h2>
        <GridImages data={list} />
      </div>
      <div className="lg:mt-20 mt-10 mb-16 lg:mb-20">
        <h2 className="lg:text-2xl text-xl font-bold text-gray-600 mb-6 lg:mb-8">热门文章</h2>
        <div className="bg-gray-20 relative cursor-pointer h-45 pt-10 pl-10 overflow-hidden" onClick={handeGoCmsContent}>
          <div className="text-gray-600 font-bold lg:text-2xl text-lg">测量洞见</div>
          <p className="text-gray-500 font-[350] lg:text-lg text-sm lg:mt-2 mt-2">测量行业干货&最新产品资讯</p>
          <Image
            className="absolute right-0 top-0"
            src="https://czcn57r01sa001p.blob.core.chinacloudapi.cn/ecommerce-resources/cms/popular-category-images/web/cms.png"
            alt="cms"
            width={466}
            height={180}
          />
        </div>
      </div>
    </>
  )
}

export default PopularProductsCategories
