import { QueryWebBannersResponse } from '@/clients/site-config-service-client/model'
import { usePost } from '@/hooks/use-request'

import { useSWR, useSWRMutation } from './swr/swr-creator'

export const useQueryBannerList = () => {
  const url = '/banner/banner-list'

  return useSWRMutation<QueryWebBannersResponse[]>(...usePost(url))
}
