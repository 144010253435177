import { FC } from 'react'

export const WithPage = (Comp: FC) => {
  const PageWithClassName = (props: any) => (
    <div className="px-8 lg:px-30">
      <Comp {...props} />
    </div>
  )

  return PageWithClassName
}
