import { useGet } from '@/hooks/use-request'
import { PromotionBrief, PromotionDetail } from '@/models/promotions/promotion'

import { useSWR } from './swr/swr-creator'

export const usePromotions = (authenticated: boolean) => {
  const url = `/promotions?channel=web&authenticated=${authenticated ? 1 : 0}`
  const [key, fetcher] = useGet<PromotionBrief[]>(url)
  return useSWR<PromotionBrief[]>(key, fetcher)
}

export const usePromotionDetail = (id: string) => {
  const url = `/promotions/${id}`
  const [key, fetcher] = useGet<PromotionDetail>(url)
  return useSWR<PromotionDetail>(id ? key : null, fetcher)
}
