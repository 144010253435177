export interface ProductBrief {
  id: string
  name: string
}

export interface ProductConstruction {
  name: string
}

export interface RecommendId {
  id: string
  productId: string
}

export interface RecommendProduct {
  id: string
  name: string
  pictureUrl: string
  price: string
}

export interface RecommendProductResponse {
  list: RecommendProduct[]
  authorized: boolean
}

export enum CategoryStyleEnum {
  default = 0,
  New = 1,
}

export interface RecommendCategory {
  sort: number
  id: string
  secondLevelCategoryId: string
  name: string
  recommendation: string
  desktopPictureUrl: string
  tabletPictureUrl: string
  mobilePictureUrl: string
  imgClassName?: string
  style: CategoryStyleEnum
}

export interface ProductAvailableInventory {
  productId: string
  availableQuantity: number
}

export interface ProductIdsList {
  ids: string[]
}
